@font-face {
font-family: '__NexaFont_6ba67c';
src: url(/_next/static/media/cbde5894c9ed7321-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__NexaFont_6ba67c';
src: url(/_next/static/media/80e3fc43cc42b3e3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__NexaFont_Fallback_6ba67c';src: local("Arial");ascent-override: 65.16%;descent-override: 27.93%;line-gap-override: 18.62%;size-adjust: 107.43%
}.__className_6ba67c {font-family: '__NexaFont_6ba67c', '__NexaFont_Fallback_6ba67c'
}

